import React, {Component} from "react";

import CourseService from "../../../network/services/CourseService";
import Sidebar from "../sidebar/Sidebar";
import AdminFooter from "../footer/AdminFooter";
import SessionService from "../../../network/services/SessionService";
import AdminHeader from "../header/AdminHeader";
import '../Dashboard/AdminHome.css';
import SubscriptionPlanService from "../../../network/services/SubscriptionPlanService";
import moment from "moment";

class SessionDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            sessions: [],
            plans: [],
            planIds: [],
            sessionPlans: [],
            plansToShow: [],
            selectedCourses: [],
            sessionCourses: [],
            selectedPlans: [],
            selectedSessionId: "",
            courses: [],
            resources: [],
            coursesToShow: [],
            currentSession: '',
            removeCourses: [],
            removePlans: [],
            selectedResources: [],
            selectedCourseId: 1,
            isLoading: false,
            planIdToDelete: '',
            courseIdToDelete: '',
            message: '',
            successMessage: false,
            currentCourse: {
                id: null,
                title: "",
                descritpion: "",
                resources: [],
            }
        }
    }

    retrievePlan = () => {
        SubscriptionPlanService.getAll((data) => {
            // console.log(data);
            this.setState({plans: data});
        });
    }

    handleChangePlan = (event) => {
        let planId = event.target.value.toString();
        if (!this.state.planIds.includes(planId)) {
            this.setState({planIds: [...this.state.planIds, planId]});
        } else {
            let tabId = this.state.planIds.filter((e) => e !== planId);
            this.setState({planIds: tabId});
        }
    };

    /* retrieveCourse = () => {
        //this.setState({ isLoading:true});
        CourseService.getAll((data) => {
            //console.log(data);
            this.setState({courses: data});
        });
    } */

    removeCourse = (e) => {

        this.getId(e);

        const tabRes = this.state.sessionCourses;

        tabRes.forEach(o => {
            let elt = "/api/courses/" + o.id;
            this.state.removeCourses.push(elt);
        });

        //let toRemove = e.target.getAttribute("value");
        let toRemove = this.state.courseIdToDelete;

        let tabCourseNew = this.state.removeCourses.filter(e => e !== toRemove);

        this.setState({
            removeCourses: []
        });

        let tabData = {
            courses: tabCourseNew,
        };

        SessionService.update(
            this.state.id,
            tabData,
            () => {
                this.getId(e);
                this.setState({message: 'The course has been removed successfully', successMessage: true})
            }
        );
    }

    removePlan = (e) => {
        this.setState({
            isLoading: true,
        });

        const IdPlan = this.state.planIdToDelete;
        const IdSession = this.state.sessionIdToRemove;

        //console.log('lid dans la session de sortie cest ' + IdSession);
        //console.log('lid du plan la session de sortie cest ' + IdPlan);

        let data = {
            session_id: IdSession,
            subscription_plan_id_array: IdPlan
        };

        SessionService.delPlan(data, () => {

            console.log(data);
            //this.selectSession(e);
            this.setState({sessionIdToRemove: "", planIdToDelete: ""});
            this.setState({
                message: "The subscription plan has been removed successfully",
                successMessage: true,
                isLoading: false
            });
            this.componentDidMount();
        });

    };

    selectSession = (e) => {
        const id = e.target.getAttribute("id");
        // console.log("the selected session is: " + id);
        SessionService.getById(id, (data) => {
            // console.log(data);
            this.setState({
                currentSession: data,
                //coursesToShow: data.courses
                coursesToShow: this.transformByDifference(
                    this.state.courses,
                    data.courses
                ),
                plansToShow: this.transformByDifference(
                    this.state.plans,
                    data.subscriptionPlans
                ),
            });
        });

        this.setState({selectedSessionId: id});

        // console.log("nous sortons pour le sendaata");
        // console.log(this.state.currentSession);
    };

    sendData = (e) => {

        const courseId = this.state.id;
        //this.state.selectedResources = this.state.currentCourse.resources;
        //console.log("taille de depart " + this.state.selectedResources.length)

        const name = e.target.value;
        this.state.selectedResources.push(name);
        console.log("taille de d ajout " + this.state.selectedResources.length)
        let TabData = {
            resources: this.state.selectedResources,
        };
        // console.log("Ce qui va au serveur");
        // console.log(TabData);
        CourseService.update(
            courseId,
            TabData,
            () => {
                this.getId();
            }
        );
    }

    getId = () => {
        const id = this.state.id;
        SessionService.getById(id, (data) => {
            //console.log(data);
            this.setState({
                currentSession: data,
                sessionPlans: data.subscriptionPlans,
                sessionCourses: data.courses,
                isLoading: false
            });
        });
    }

    sendCourseData = (e) => {

        //const sessionId = this.state.selectedSessionId;
        const sessionId = this.state.id;

        const tabRes = this.state.currentSession.courses;

        tabRes.forEach(o => {
            let elt = "/api/courses/" + o.id;
            this.state.selectedCourses.push(elt);
        });
        const toAdd = e.target.value;
        // console.log("on veut ajouter "+toAdd)

        this.state.selectedCourses.push(toAdd);

        // console.log('le tableau en sorte est ')
        // console.log(this.state.selectedCourses)
        let TabData = {
            courses: this.state.selectedCourses,
        };

        tabRes.push(toAdd);
        this.setState({
            selectedCourses: [],
            //   coursesToShow: [],
        });
        SessionService.update(
            sessionId,
            TabData,
            () => {
                SessionService.getById(sessionId, (data) => {
                    console.log(data);
                    this.setState({
                        currentSession: data,
                    });
                });
                this.getId();
                this.setState({message: 'The course has been associated successfully', successMessage: true})
            }
        );
    }

    // savePlanIdToRemove = (e) => {

    //     const idSession = e.target.getAttribute("id");
    //     const idPlan = e.target.getAttribute("value");

    //     if (!this.state.planIdToDelete.includes(idPlan)) {
    //         this.setState({planIdToDelete: [...this.state.planIdToDelete, idPlan]});
    //     } else {
    //         let tabId = this.state.planIdToDelete.filter((e) => e !== idPlan);
    //         this.setState({planIdToDelete: tabId})
    //     }
    //     this.setState({sessionIdToRemove: idSession});
    // };
    savePlanIdToRemove = (e) => {
        const idSession = e.target.getAttribute("id");
        const idPlan = e.target.getAttribute("value");
    
        console.log("ID de la session :", idSession);
        console.log("ID du plan :", idPlan);
        console.log("Plan IDs à supprimer avant :", this.state.planIdToDelete);
    
        if (!this.state.planIdToDelete.includes(idPlan)) {
            this.setState(
                (prevState) => ({
                    planIdToDelete: [...prevState.planIdToDelete, idPlan],
                }),
                () => {
                    console.log("Plan IDs à supprimer après ajout :", this.state.planIdToDelete);
                }
            );
        } else {
            let tabId = this.state.planIdToDelete.filter((e) => e !== idPlan);
            this.setState(
                { planIdToDelete: tabId },
                () => {
                    console.log("Plan IDs à supprimer après suppression :", this.state.planIdToDelete);
                }
            );
        }
    
        this.setState({ sessionIdToRemove: idSession }, () => {
            console.log("ID de session après mise à jour :", this.state.sessionIdToRemove);
        });
    };

    saveCourseIdToRemove = (e) => {
        const toRemove = e.target.getAttribute("value");
        //console.log("on veut supprimer "+toRemove)
        this.setState({courseIdToDelete: toRemove})
    }

    sendPlanData = (e) => {
        this.setState({
            isLoading: true,
        });
        const planId = this.state.planIds;
        const sessionId = this.state.selectedSessionId;

        console.log("La session choisie est " + sessionId);
        //console.log("Les plan choisie " + planId);

        let tabData = {

            session_id: sessionId,
            subscription_plan_id_array: planId
        };

        SessionService.atriplan(tabData, () => {
            console.log(tabData);
            this.setState({
                planIds: [],
                selectedSessionId: "",

            });

            SessionService.getById(sessionId, (data) => {
                console.log(data);
                // console.log(data);
                this.setState({
                    message: "The subscription plan has been associated successfully",
                    successMessage: true,
                });
                this.setState({
                    currentSession: data,
                    isLoading: false
                    // isLoading:false
                });

            });

            let inputs2 = document.querySelectorAll("#search-field");
            for (let i = 0; i < inputs2.length; i++) {
                inputs2[i].checked = false;
            }

            this.componentDidMount();
        });
    };

    transformByDifference = (tab1, tab2) => {
        // console.log("taille du tableau 1 ");
        // console.log(tab1);
        // console.log("taille du tableau 2 ");
        // console.log(tab2);
        let courseIds = tab2.map(c => c.id);

        // console.log(courseIds);

        let availableCourses = tab1.filter(ac => !courseIds.includes(ac.id));

        // console.log("taille du tableau de sortie " + availableCourses.length);
        // console.log(availableCourses);
        return availableCourses;
    }

    componentWillMount() {
        const queryParams = new URLSearchParams(window.location.search);
        let identifiant = queryParams.get('sessionId');
        this.setState({id: identifiant});
    }

    componentDidMount() {
        this.setState({isLoading: true});
        this.getId();
        this.retrievePlan()
        // this.retrieveCourse()
        this.setState({isLoading: false});
    }

    render() {
        return (
            <div className="g-sidenav-show  bg-gray-200">
                <Sidebar/>
                <main className="mainAdmin r ms-100">
                    <AdminHeader location="Session / details"/>
                    <div className="container-fluid py-4">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">

                                    <div className="col-md-12 mb-lg-0 mb-4">
                                        <div className="card mt-4">
                                            <div className="card-header pb-0 p-3">
                                                <div className="row">
                                                    <div className="col-6 d-flex align-items-center">
                                                        <h6 className="mb-0">Details on Session
                                                            : {this.state.currentSession.name}</h6>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <table className="table table-borderless">
                                                    {
                                                        this.state.isLoading ? (
                                                                <div className="spinner-border text-primary" role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>

                                                            ) :
                                                            null
                                                    }
                                                    <tbody>

                                                    {/*<tr>*/}
                                                    {/*    <td><img src="../assets/img/team-2.jpg"*/}
                                                    {/*             className="avatar border-radius-lg"*/}
                                                    {/*             alt={this.state.currentSession.name}/></td>*/}
                                                    {/*    <td></td>*/}
                                                    {/*</tr>*/}
                                                    <tr>
                                                        <td> ID</td>
                                                        <td>{this.state.currentSession.id}</td>
                                                    </tr>
                                                    <tr>
                                                        <td> Title</td>
                                                        <td>{this.state.currentSession.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td> Description</td>
                                                        <td>
                                                            <div
                                                                dangerouslySetInnerHTML={{__html: this.state.currentSession.description}}></div>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Session description link</td>
                                                        <td>{this.state.currentSession.descriptionLink}</td>
                                                    </tr>
                                                    <tr>
                                                        <td> WP course link</td>
                                                        <td>
                                                            {this.state.currentSession.wordpressCourse}</td>
                                                    </tr>
                                                    <tr>
                                                        <td> Instructor words</td>
                                                        <td>
                                                            {this.state.currentSession.ckeditor}</td>
                                                    </tr>
                                                    <tr>
                                                        <td> WP GROUP ID</td>
                                                        <td>
                                                            {this.state.currentSession.wordpressGroupId}</td>
                                                    </tr>
                                                    <tr>
                                                        <td> MD GROUP ID</td>
                                                        <td>
                                                            {this.state.currentSession.moodleGroupId}</td>
                                                    </tr>
                                                    {this.state.currentSession.customSessionPaymentDate ? <tr>
                                                        <td> Custom Payment date</td>
                                                        <td>
                                                            {this.state.currentSession.customSessionPaymentDate ? "Every " + moment.localeData().ordinal(parseInt(moment(this.state.currentSession.customSessionPaymentDate).utc().format('DD')))
                                                                + ' at '
                                                                + moment.utc(this.state.currentSession.customSessionPaymentDate).format("HH:mm")
                                                                + " from "
                                                                + moment.utc(this.state.currentSession.customSessionPaymentDate).format("DD MMMM YYYY") : '-'}
                                                        </td>
                                                    </tr> : null}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="card h-100">
                                    <div className="card-header pb-0 p-3">
                                        <div className="row">

                                            <div className="col-6 d-flex align-items-center">
                                                <h6 className="mb-0">Associated Subscription Plans</h6>
                                            </div>
                                            <div className="col-6 text-end">
                                                <button className="btn btn-outline-primary btn-sm mb-0"
                                                        id={this.state.currentSession.id}
                                                        onClick={this.selectSession}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModalAddPlan"
                                                >Add
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-3 pb-0">
                                        <table className="table table-borderless">
                                            <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>...</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {
                                                this.state.isLoading ? (
                                                        <div className="spinner-border text-primary" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>

                                                    ) :
                                                    null
                                            }
                                            {
                                                this.state.sessionPlans.map((plan) => (

                                                    <tr>
                                                        <td>{plan.name}</td>
                                                        <td>
                                                            <button
                                                                style={{cursor: 'pointer', color: 'firebrick'}}
                                                                id={this.state.currentSession.id}
                                                                value={plan.id}
                                                                className="badge "
                                                                data-bs-target="#confirmationRemoveModal"
                                                                data-bs-toggle="modal"
                                                                onClick={this.savePlanIdToRemove}
                                                                //onClick={this.removePlan}
                                                            >Remove
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mt-4">
                                <div className="card">
                                    <div className="card-header pb-0 p-3">
                                        <div className="row">
                                            <div className="col-6 d-flex align-items-center">
                                                <h6 className="mb-0">Associated courses</h6>
                                            </div>
                                            <div className="col-6 text-end">
                                                <button className="btn btn-outline-primary btn-sm mb-0"
                                                        onClick={() => {
                                                            const tab = this.transformByDifference(this.state.courses, this.state.sessionCourses);
                                                            this.setState({coursesToShow: tab})
                                                        }}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModalAdd"
                                                >Add
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body pt-4 p-3">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>...</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {
                                                this.state.sessionCourses && this.state.sessionCourses.map((course) => (

                                                    <tr>
                                                        <td value={'/api/resources/' + course.id}
                                                            key={course.id}>{course.id}</td>
                                                        <td>{course.title}</td>
                                                        <td> <span className='material-icons'
                                                                   style={{cursor: 'pointer', color: 'firebrick'}}
                                                                   id={course.id}
                                                                   key={course.id}
                                                                   value={"/api/courses/" + course.id}
                                                                   data-bs-target="#confirmationRemoveCourseModal"
                                                                   data-bs-toggle="modal"
                                                                   onClick={this.saveCourseIdToRemove}

                                                        >cancel</span>
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="modal fade"
                            id="exampleModalAddPlan"
                            tabIndex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true">

                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5
                                            className="modal-title"
                                            id="exampleModalLabel"
                                        >
                                            Associated Plans
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <table className="table table-borderless">
                                            <thead>
                                            <tr>
                                                <th>...</th>
                                                <th>Name</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {this.state.isLoading ? (
                                                <div
                                                    className="spinner-border text-primary"
                                                    role="status"
                                                >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            {this.state.plansToShow &&
                                                this.state.plansToShow.map((plan) => (
                                                    <tr key={plan.id}>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <input
                                                                    type="checkbox"
                                                                    id="search-field"
                                                                    value={plan.id}
                                                                    onClick={this.handleChangePlan}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>{plan.name}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="modal-footer">
                                        {/*<button
                                            type="button"
                                            className="btn bg-gradient-success"
                                            data-bs-dismiss="modal"
                                            id="search-Field"
                                            onClick={this.sendPlanData}
                                        >
                                            Associate
                                        </button>*/}
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-success"
                                            data-bs-dismiss="modal"
                                            id="search-Field"
                                            disabled={(this.state.planIds).length < 1}
                                            onClick={this.sendPlanData}
                                        >
                                            Associate
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="modal fade"
                            id="exampleModalAdd"
                            tabIndex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true">

                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">
                                            Associated Courses
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <table className="table table-borderless">
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>...</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {

                                                //this.state.coursesToShow && this.state.coursesToShow.map((course) => (
                                                this.state.coursesToShow && this.state.coursesToShow.map((course) => (

                                                    <tr key={course.id}>
                                                        <td value={'/api/courses/' + course.id}>{course.id}</td>
                                                        <td>{course.title}</td>
                                                        <td>
                                                            <button
                                                                value={'/api/courses/' + course.id}
                                                                className="badge badge-sm bg-gradient-success"
                                                                data-bs-dismiss="modal"
                                                                onClick={this.sendCourseData}>Associate
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="modal fade"
                            id="confirmationRemoveModal"
                            tabIndex="-1"
                            aria-labelledby="confirmationRemoveModal"
                            aria-hidden="true"
                        >
                            <div
                                className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title"
                                            id="exampleModalLabel">
                                            Dissociate subscription plan
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        Do you want to remove the selected
                                        subscription plan from the session ?
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-danger  btn-block"
                                            onClick={this.removePlan}
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Confirm
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary btn-block"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div
                            className="modal fade"
                            id="confirmationRemoveCourseModal"
                            tabIndex="-1"
                            aria-labelledby="confirmationRemoveCourseModal"
                            aria-hidden="true"
                        >
                            <div
                                className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title"
                                            id="exampleModalLabel">
                                            Dissociate course
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        Do you want to remove the selected
                                        course from the session ?
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-danger  btn-block"
                                            onClick={this.removeCourse}
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Confirm
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary btn-block"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="position-fixed bottom-1 end-1 z-index-2">


                            {this.state.successMessage ? (
                                    <div className="toast fade show p-2 mt-2 bg-gradient-info" role="alert"
                                         aria-live="assertive" id="infoToast" aria-atomic="true">
                                        <div className="toast-header bg-transparent border-0">
                                            <i className="material-icons text-white me-2">
                                                check
                                            </i>
                                            <span className="me-auto text-white font-weight-bold">Session details</span>
                                            <small className="text-white">just now</small>
                                            <i className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                               data-bs-dismiss="toast" aria-label="Close"
                                               onClick={(e) => this.setState({successMessage: false})}
                                            ></i>
                                        </div>
                                        <hr className="horizontal light m-0"/>
                                        <div className="toast-body text-white">
                                            {this.state.message}
                                        </div>
                                    </div>
                                ) :
                                (<></>)}
                            {this.state.updateMessage ? (
                                    <div className="toast fade show p-2 mt-2 bg-white" role="alert"
                                         aria-live="assertive" id="warningToast" aria-atomic="true">
                                        <div className="toast-header border-0">
                                            <i className="material-icons text-warning me-2">
                                                check
                                            </i>
                                            <span className="me-auto font-weight-bold">Session</span>
                                            <small className="text-body">just now</small>
                                            <i className="fas fa-times text-md ms-3 cursor-pointer"
                                               data-bs-dismiss="toast" aria-label="Close"
                                               onClick={(e) => this.setState({updateMessage: false})}
                                            ></i>
                                        </div>
                                        <hr className="horizontal dark m-0"/>
                                        <div className="toast-body">
                                            Session successfully updated.
                                        </div>
                                    </div>
                                ) :
                                (<></>)}

                            {this.state.deleteMessage ? (
                                    <div className="toast fade show p-2 mt-2 bg-white" role="alert"
                                         aria-live="assertive" id="warningToast" aria-atomic="true">
                                        <div className="toast-header border-0">
                                            <i className="material-icons text-warning me-2">
                                                check
                                            </i>
                                            <span className="me-auto font-weight-bold">Session </span>
                                            <small className="text-body">just now</small>
                                            <i className="fas fa-times text-md ms-3 cursor-pointer"
                                               data-bs-dismiss="toast" aria-label="Close"
                                               onClick={(e) => this.setState({deleteMessage: false})}
                                            ></i>
                                        </div>
                                        <hr className="horizontal dark m-0"/>
                                        <div className="toast-body">
                                            {this.state.deleteMessageContent}
                                        </div>
                                    </div>
                                ) :
                                (<></>)}
                        </div>
                        <AdminFooter/>
                    </div>
                </main>
            </div>
        )
    }
}

export default SessionDetails;