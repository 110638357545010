import UserService from "../services/UserService";
import {
  AUTH_SETTINGS_DELAY,
  AUTH_SETTINGS_MINUTE,
} from "../../constants/Constants";

export const TOKEN_LIFE_TIME = 314496 * 1000; // 364 days of life time
export const TOKEN_KEY = "token";
export const EXPIRE_AT_KEY = "expired_at";
export const ROLE_KEY = "role";
export const USER_KEY = "user";
export const LAST_URL = "LAST_URL";

export const AUTH_SETTINGS_KEY_EXPIRED_AT = "auth_settings_expired_at";

export const AUTH_SETTINGS_KEY = "settings";

export const IS_ELIGIBLE_DISCOUNT = "isEligibleToDiscount";
export const IS_ELIGIBLE_DISCOUNT_DATE = "isEligibleToDiscountDate";

//This is for managing the open collapse in admin sidebar
export const COLLAPSED_KEY = "0";
export const ACTIVE_TAB = "/admin";

// export const APP_ROLES = {
//   ROLE_USER: "ROLE_USER",
//   ROLE_ADMIN: "ROLE_ADMIN",
//   ROLE_ACCOUNTING: "ROLE_ACCOUNTING",
//   ROLE_MANAGER: "ROLE_MANAGER",
//   ROLE_COURSE: "ROLE_COURSE",
//   ROLE_SUPPORT: "ROLE_SUPPORT",
// };
// const APP_ROLES_HIERARCHY = {
//   ROLE_ADMIN: [
//     APP_ROLES.ROLE_USER,
//     APP_ROLES.ROLE_MANAGER,
//     APP_ROLES.ROLE_COURSE,
//     APP_ROLES.ROLE_ACCOUNTING,
//     APP_ROLES.ROLE_SUPPORT,
//   ],
//   ROLE_ACCOUNTING: [APP_ROLES.ROLE_USER, APP_ROLES.ROLE_MANAGER],
//   ROLE_COURSE: [APP_ROLES.ROLE_MANAGER, APP_ROLES.ROLE_USER],
//   //ROLE_SUPPORT: [APP_ROLES.ROLE_MANAGER, APP_ROLES.ROLE_USER],
//   ROLE_SUPPORT: [APP_ROLES.ROLE_USER],
//   ROLE_MANAGER: [APP_ROLES.ROLE_USER],
// };

export const APP_ROLES = {
  ROLE_USER: "ROLE_USER",
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_ACCOUNTING: "ROLE_ACCOUNTING",
  ROLE_MANAGER: "ROLE_MANAGER",
  ROLE_COURSE: "ROLE_COURSE",
  ROLE_INSTRUCTOR: "ROLE_INSTRUCTOR", 
  ROLE_SUPPORT: "ROLE_SUPPORT",
};

const APP_ROLES_HIERARCHY = {
  ROLE_ADMIN: [
    APP_ROLES.ROLE_USER,
    APP_ROLES.ROLE_MANAGER,
    APP_ROLES.ROLE_COURSE,
    APP_ROLES.ROLE_ACCOUNTING,
    APP_ROLES.ROLE_SUPPORT,
    APP_ROLES.ROLE_INSTRUCTOR, 
  ],
  ROLE_ACCOUNTING: [APP_ROLES.ROLE_USER, APP_ROLES.ROLE_MANAGER],
  ROLE_COURSE: [APP_ROLES.ROLE_MANAGER, APP_ROLES.ROLE_USER],
  ROLE_INSTRUCTOR: [APP_ROLES.ROLE_MANAGER, APP_ROLES.ROLE_USER],
  ROLE_SUPPORT: [APP_ROLES.ROLE_USER],
  ROLE_MANAGER: [APP_ROLES.ROLE_USER],
};

export const saveAuthToken = (accessToken, expiredAt) => {
  localStorage.setItem(TOKEN_KEY, accessToken);
  localStorage.setItem(EXPIRE_AT_KEY, expiredAt.toString());
};

export const setLastUrl = (url) => {
  localStorage.setItem(LAST_URL, url);
};
export const removeLastUrl = (url) => {
  let last = getLastUrl();
  if (last != null) {
    localStorage.removeItem(LAST_URL);
  }
};
export const getLastUrl = () => {
  const url = localStorage.getItem(LAST_URL);
  return url;
};
export const removeAuthToken = () => {
  localStorage.removeItem(EXPIRE_AT_KEY);
  localStorage.removeItem(TOKEN_KEY);
};

export const getAuthToken = () => {
  const accessToken = localStorage.getItem(TOKEN_KEY) || null;
  const expiresAt = Number(localStorage.getItem(EXPIRE_AT_KEY)) || null;
  const role = localStorage.getItem(ROLE_KEY) || null;
  const userSt = localStorage.getItem(USER_KEY) || null;
  const user = JSON.parse(userSt);
  return {
    expiresAt,
    accessToken,
    role,
    user,
  };
};

export const getBearerAccessToken = () => {
  return getAuthToken().accessToken;
};

export const getRole = () => {
  return getAuthToken().role;
};

export const getUser = () => {
  return getAuthToken().user;
};

export const signIn = (data) => {
  let now = new Date().getTime();
  let expiresAt = JSON.stringify(now + TOKEN_LIFE_TIME);
  localStorage.setItem(TOKEN_KEY, data.token);
  localStorage.setItem(ROLE_KEY, data.role);
  localStorage.setItem(EXPIRE_AT_KEY, expiresAt);
  localStorage.setItem(USER_KEY, JSON.stringify(data.user));
};

export const logout = async () => {
  // Clear access token and ID token from local storage
  //UserService.logout((data)=>{ alert(data.date);});
  // const { message, success } = await UserService.logoutAsync();
  //alert(success);
  //alert(message);

  removeLastUrl();
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(ROLE_KEY);
  localStorage.removeItem(EXPIRE_AT_KEY);
  localStorage.removeItem(USER_KEY);
  localStorage.removeItem(IS_ELIGIBLE_DISCOUNT);
};

export const isAuthenticated = () => {
  // Check whether the current time is past the
  // access token's expiry time
  let expiresAt = JSON.parse(localStorage.getItem(EXPIRE_AT_KEY));

  let now = new Date().getTime();
  //alert ("expireAt: "+expiresAt+" and now: "+now);
  if (expiresAt) {
    return now < expiresAt;
  }
  return false;
};

export const hasCredential = (role) => {
  if (!isAuthenticated()) return false;
  const currentRole = getRole();
  return hasRole({ role: currentRole }, role);
};
export const hasRole = (user, role) => {
  const currentRole = user.role;
  if (currentRole !== null || currentRole !== undefined) {
    if (role === currentRole) {
      return true;
    }
    if (APP_ROLES_HIERARCHY[currentRole]) {
      let subRoles = APP_ROLES_HIERARCHY[currentRole];
      for (let i = 0; i < subRoles.length; i++) {
       
        if (subRoles[i] === role) {
          return true;
        }
      }
    }
  }
  return false;
};
export const refreshUser = async () => {
  if (!isAuthenticated()) return false;
  const user = await UserService.refreshUser();
  if (user) {
    localStorage.removeItem(USER_KEY);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
    return true;
  } else {
    return false;
  }
};

export const refreshAndGetUser = async () => {
  if (!isAuthenticated()) return null;
  const user = await UserService.refreshUser();
  if (user) {
    localStorage.removeItem(USER_KEY);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
    return user;
  } else {
    return null;
  }
};

//This function is called to set the key for the open collapsed on sidebar
export const setCollapseKey = (elt) => {
 
  localStorage.setItem(COLLAPSED_KEY, elt);
};
export const getCollapseKey = () => {
  return localStorage.getItem(COLLAPSED_KEY);
};
export const setActiveTab = (elt) => {

  localStorage.setItem(ACTIVE_TAB, elt);
};

export const getActiveTab = () => {
  return localStorage.getItem(ACTIVE_TAB);
};
export const redirectionUrl = async (role) => {
  if (!isAuthenticated()) return "/login";
  if (role === "ROLE_USER") {
    return "/workspace/mysessions";
  } else {
    let lastUrl = getLastUrl();
     
console.log("INSTRUCTOR ROLE HERE")

    if (lastUrl === process.env.REACT_APP_COURSE_LMS + "/login") {

      const { otp } = await UserService.generateOtp();
      if (otp) {
        removeLastUrl();
        return process.env.REACT_APP_COURSE_LMS + "/login" + "?otp=" + otp;
      }
    } else { 
      console.log("ROLE",role)
      if (role === "ROLE_SUPPORT") {
        return "/search-user";
      } else if (role === "ROLE_ADMIN") {
        return "/admin";
      } else if (role === "ROLE_INSTRUCTOR") {
        return "/admin";
      } else if (role === "ROLE_COURSE") {
        return "/admin";
      }else if (role === "ROLE_ACCOUNTING") {
        return "/admin";
      }

     
    }
  }
};

export const saveAuthSettings = (data) => {
  let now = new Date().getTime();
  let expiresAt = JSON.stringify(now + AUTH_SETTINGS_DELAY);
  localStorage.setItem(AUTH_SETTINGS_KEY, JSON.stringify(data));
  localStorage.setItem(AUTH_SETTINGS_KEY_EXPIRED_AT, expiresAt);
};
export const getAuthSettings = () => {
  let expiresAt = JSON.parse(
    localStorage.getItem(AUTH_SETTINGS_KEY_EXPIRED_AT)
  );

  let now = new Date().getTime();
  if (now < Number(expiresAt)) {
    return JSON.parse(localStorage.getItem(AUTH_SETTINGS_KEY));
  }
  return null;
};

export const saveDiscountEligibility = (data) => {
  let now = new Date().getTime();
  let expiresAt = JSON.stringify(now + AUTH_SETTINGS_MINUTE);

  localStorage.setItem(IS_ELIGIBLE_DISCOUNT, JSON.stringify(data));
  localStorage.setItem(IS_ELIGIBLE_DISCOUNT_DATE, JSON.stringify(expiresAt));
};

export const isDiscountEligibility = () => {
  return JSON.parse(localStorage.getItem(IS_ELIGIBLE_DISCOUNT));
};
