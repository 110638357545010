const routeInstructor = [

    {
        path: '/admin',
        name: 'Dashboard',
        icon: 'dashboard',
        section: 1
    },
   
    {
        path: '/search-user',
        name: 'Transactions',
        icon: 'list',
        section: 1
    },
    {
        path: '/admin-resources',
        name: 'Resources',
        icon : 'attach_file',
        section: 1
    },
   
    
];
export default routeInstructor;
