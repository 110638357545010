import React, { Component } from "react";
import Sidebar from "../sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import "../../../assets/subscription_plan.css";
import CourseService from "../../../network/services/CourseService";
import SubscriptionPlanService from "../../../network/services/SubscriptionPlanService";
import resourceService from "../../../network/services/ResourceService";
import ReactPaginate from "react-paginate";
import AdminHeader from "../header/AdminHeader";
import "../Dashboard/AdminHome.css";
import moment from "moment/moment";

class AdminCourses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      successMessage: false,
      firstLoader: false,
      subscriptionIdPlan: "",
      echecMessageContent: "",
      selectedSessionId: "",
      sessionIdToRemove: "",
      deleteMessageContent: "",
      currentPage: "",
      image: null,
      selectedPlans: [],
      planIds: [],
      courses: [],
      courseIds: [],
      coursesToShow: [],
      plansToShow: [],
      lastPage: 1,
      update: "",
      currentCourse: "",
      wordpressCourse: "",
      wordpressGroupId: "",
      moodleGroupId: "",
      name: "",
      createdAt: "",
      courseId: "",
      description: "",
      subscriptionPlans: [],
      plans: [],
      planIdToDelete: [],
      resources: [],
      selectedResources: [],
      selectedCourseId: "",
      isLoading: false,
      itemPerPage: 10,
      courseIdToRemove: "",
      planIdToRemove: "",
      message: "",
      courseSubscriptionStatusFilter: "",
      coursePublicationStatusFilter: "",
      courseStatus: "",
      coursePublicationStatus: "",
      PublicationStatus: "",
      SubscriptionStatusSetting: null,
      PublicationStatusSetting: null,
    };
  }

  onCourseStatusChange = (e) => {
    const text = e.target.value;

    this.setState(function (prevState) {
      return {
        currentCourse: {
          ...prevState.currentCourse,
          status: text,
        },
      };
    });
  };

  onCoursePublicationStatusChange = (e) => {
    const text = e.target.value;
    this.setState(function (prevState) {
      return {
        currentCourse: {
          ...prevState.currentCourse,
          publication_status: text,
        },
      };
    });
  };

  openDetails = (e) => {
    const id = e.target.getAttribute("id");
    console.log("the selected course is: " + id);

    const navigate = this.props.myNavigate;
    //alert(navigate);
    navigate({
      pathname: "/admin-courses-details",
      search: "?courseId=" + id,
    });
  };

  onNameChange = (e) => {
    const name = e.target.value;

    this.setState(function (prevState) {
      return {
        currentCourse: {
          ...prevState.currentCourse,
          name: name,
        },
      };
    });
  };

  onWordpressCourseChange = (e) => {
    const text = e.target.value;

    this.setState(function (prevState) {
      return {
        currentCourse: {
          ...prevState.currentCourse,
          wordpressCourse: text,
        },
      };
    });
  };

  onWordpressCourseIdChange = (e) => {
    const wordpressGroupId = e.target.value;

    this.setState(function (prevState) {
      return {
        currentCourse: {
          ...prevState.currentCourse,
          wordpressGroupId: wordpressGroupId,
        },
      };
    });
  };

  onMoodleCourseIdChange = (e) => {
    const moodleGroupId = e.target.value;

    this.setState(function (prevState) {
      return {
        currentCourse: {
          ...prevState.currentCourse,
          moodleGroupId: moodleGroupId,
        },
      };
    });
  };

  onMoodleCourseChange = (e) => {
    const text = e.target.value;

    this.setState(function (prevState) {
      return {
        currentCourse: {
          ...prevState.currentCourse,
          moodleCourse: text,
        },
      };
    });
  };

  onDescriptionChange = (e) => {
    const description = e.target.value;

    this.setState(function (prevState) {
      return {
        currentCourse: {
          ...prevState.currentCourse,
          description: description,
        },
      };
    });
  };

  onCreatedAtChange = (e) => {
    const createdAt = e.target.value;

    this.setState(function (prevState) {
      return {
        currentCourse: {
          ...prevState.currentCourse,
          createdAt: createdAt,
        },
      };
    });
  };

  onEndDateChange = (e) => {
    const endDate = e.target.value;

    this.setState(function (prevState) {
      return {
        currentCourse: {
          ...prevState.currentCourse,
          endDate: endDate,
        },
      };
    });
  };

  handleUploadImage = (event) => {
    const uploadImage = event.target.files[0];
    const imageUrl = URL.createObjectURL(uploadImage);

    this.setState({ imageUrl: imageUrl });
    this.setState({ image: uploadImage });
  };

  onFileChange = (event) => {
    let file = event.target.files[0];
    this.setState({ image: file });
  };

  saveCourse = () => {
    let d = new Date();
    let date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
    let hours = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
    let fullDate = date + "T" + hours + "Z";

    if (this.state.name == "" || this.state.description == "") {
      this.setState({
        deleteMessage: !this.state.deleteMessage,
        deleteMessageContent: "Please fill all required fields",
      });
      return;
    }

    var formdata = new FormData();

    formdata.append("name", this.state.name);
    formdata.append("wordpressGroup_id", this.state.wordpressGroupId);
    formdata.append("moodleGroupId", this.state.moodleGroupId);

    formdata.append("description", this.state.description);

    formdata.append("image", this.state.image);
    // formdata.append("createdAt", this.state.createdAt);

    formdata.append("courseStatus", this.state.courseStatus);
    formdata.append(
      "coursePublicationStatus",
      this.state.coursePublicationStatus
    );

    CourseService.createSimpleCourses(formdata, (response) => {
      if (response) {
        this.setState({
          successMessage: true,
          message: "Course created successfully",
          name: null,
          createdAt: "",
          end_date: null,
          description: "",
          wordpressGroupId: "",
          moodleGroupId: "",
          courseStatus: "",
          coursePublicationStatus: "",
          image: "",
          imageUrl: "",
        });

        const form = document.getElementById("create-session-form");
        form.reset();
        this.retrieveCourse();
      }
    });
  };

  updateCourse = () => {
    if (
      this.state.currentCourse.name == "" ||
      this.state.currentCourse.description == ""
    ) {
      {
        this.setState({
          deleteMessage: !this.state.deleteMessage,
          deleteMessageContent: "Please fill all required fields",
        });
        return;
      }
    }
    var formdata = new FormData();
    formdata.append("name", this.state.currentCourse.name);
    formdata.append("wordpressGroup_id", this.state.currentCourse.wordpressGroupId);
    formdata.append("moodleGroupId", this.state.currentCourse.moodleGroupId);
    formdata.append("description", this.state.currentCourse.description);

    formdata.append("image", this.state.image);

    /*    formdata.append("coursePublicationStatus", null);
              formdata.append("courseStatus", null); */

    formdata.append(
      "courseStatus",
      this.state.currentCourse.status === "OPENED" ? "1" : "0"
    );
    formdata.append(
      "coursePublicationStatus",
      this.state.currentCourse.publication_status === "PUBLISHED" ? "1" : "0"
    );

    console.log(formdata);
    CourseService.updateCourse(
      this.state.currentCourse.id,
      formdata,
      (response) => {
        // console.log(response);
        this.retrieveCourse();
        if (response) {
          this.setState({
            updateMessage: true,
          });
          const form = document.getElementById("update-session-form");
          // console.log(form);
          form.reset();
          this.retrieveCourse();
        }
      }
    );
  };

  retrieveCourse = () => {
    this.setState({ firstLoader: true });
    CourseService.GetAllSimpleCourses(1, this.state.itemPerPage, (response) => {
      // console.log("All data");
      // console.log(response);
      this.setState({
        courses: response.data,
        lastPage: response.total_pages,
        firstLoader: false,
      });
    });
  };

  delete = (e) => {
    const id = this.state.courseIdToRemove;
    console.log(id);
    CourseService.delete(id, (response) => {
      if (response) {
        if (response.status == true) {
          this.setState({
            successMessage: true,
            // deleteMessage: true,
            message: "The course has been deleted successfully.",
          });
        } else {
          console.log('hello')
          this.setState({          
            deleteMessage: true,
            deleteMessageContent:
              "Impossible to delete the course! The course already has subscriptions.",

            courseIdToRemove: "",
          });
        }
      }
      this.retrieveCourse();
    });
  };

  /*  delete = (e) => {
    const id = this.state.courseIdToRemove;

    CourseService.delete(id, (response) => {

        if (response) {
            if (response.success == true) {
              this.setState({
                deleteMessage: true,
                IdToRemove: "",
                message: "The course has been deleted successfully.",
              });

            } else {
                this.setState({
                  cancelMessage: true,
                    deleteMessageContent:
                        "Impossible to delete the course! The course already has subscriptions.",

                    courseIdToRemove: "",
                });
            }
        }
        this.retrieveCourse();

    });
}; */

  handleChange = ($event) => {
    const { name, value } = $event.target;
    this.setState({ [name]: value });
  };

  getIdResource(id) {
    resourceService.getById(id, (data) => {
      if (data) {
        this.setState({
          resource: this.state.resource.add(data),
        });
      }
    });
  }

  deleteCourse = (e) => {
    const id = e.target.getAttribute("id");
    CourseService.delete(id, () => {
      this.setState({
        deleteMessage: true,
      });
      // this.showCourse();
    });
  };

  getCourseByUrl = (e) => {
    const id = e.target.getAttribute("id");
    // console.log("the selected course is: " + id);

    this.getId(id);
  };

  componentDidMount() {
    // this.showCourse();
    //this.retrieveResourse();
    this.retrieveCourse();
    this.retrievePlan();

    /*const queryParams = new URLSearchParams(window.location.search);
                let courseId = queryParams.get("courseId");
                this.setState({ courseId: courseId }); */
  }

  handleSearch = (e) => {
    let nb = e.target.value;
    this.setState({ itemPerPage: nb });

    CourseService.GetAllSimpleCourses(1, nb, (response) => {
      // console.log("All data paginate");
      // console.log(response);
      if (response) {
        this.setState({
          courses: response.data,
          lastPage: response.total_pages,
        });
      }
    });
  };

  getId = (courseId) => {
    // console.log(courseId);
    this.setState({ isLoading: true });
    CourseService.getOneById(courseId, (response) => {
      if (response) {
        if (response) {
          console.log(response);

          this.setState({
            currentCourse: response.data,

            isLoading: false,
          });
        }
      }
    });
  };

  saveCourseIdToRemove = (e) => {
    const id = e.target.getAttribute("id");
    // console.log(id);
    this.setState({ courseIdToRemove: id });
  };

  savePlanIdToRemove = (e) => {
    const id = e.target.getAttribute("id");
    // console.log("voici le id du plan souscription " + id);

    const idv = e.target.getAttribute("value");
    // console.log("the id of course is: " + idv);

    this.setState({ planIdToRemove: id, courseIdToRemove: idv });
  };

  GetCourseFilter = () => {
    this.setState({
      isLoading: true,
    });
    let data = {
      coursePublicationStatus: this.state.coursePublicationStatusFilter,
      courseStatus: this.state.courseSubscriptionStatusFilter,
    };
    // console.log(data);
    CourseService.getCourseFilter(data, (response) => {
      if (response) {
        if (response) {
          const datas = response["data"];
          this.setState({
            courses: datas,
            lastPage: response.total_pages,
            isLoading: false,
          });
        }
      }
    });
  };

  cancelSetting = (e) => {
    this.setState({
      courseIds: [],
      courseSubscriptionStatusSetting: null,
      coursePublicationStatusSetting: null,
    });
  };

 
  cancelFilter = (e) => {
    this.setState({
      courseSubscriptionStatusFilter: "",
      coursePublicationStatusFilter: "",
    });
  };

  selectedCourseById = (e) => {
    const course_id = e.target.getAttribute("id");
    this.setState({ isLoading: true });
    CourseService.getOneById(course_id, (response) => {
      if (response) {
        if (response) {
          console.log(response);

          this.setState({
            currentCourse: response.data,
            plansToShow: this.transformByDifference(
              this.state.plans,
              response.data.subscriptionPlans
            ),
            isLoading: false,
          });
        }
      }
    });
    this.setState({ selectedCourseId: course_id });
  };

  /*   selectedCourseById = (e) => {
       const id = e.target.getAttribute("id");
       console.log("the selected course is: " + id);

       this.getId(id);
       this.setState({
       selectedId:id

       });


     };  */

  transformByDifference = (tab1, tab2) => {
    // console.log("taille du tableau 1 ");
    // console.log(tab1);
    //
    // console.log("taille du tableau 2 ");
    // console.log(tab2);

    if (tab2.length < 1) {
      return tab1;
    }
    let courseIds = tab2.map((c) => c.id);
    return tab1.filter((ac) => !courseIds.includes(ac.id));
  };


  sendPlanData = (e) => {
    this.planStop();
    /* const course_id = e.target.getAttribute("id"); */
    const course_id = this.state.selectedCourseId;
    const planId = this.state.planIds;

    let tabData = {
      course_id: course_id,
      subscription_plan_ids: planId,
    };

    if (this.state.planIds.length < 1) {
      this.setState({
        deleteMessageContent: "Please select a subscription plan",
        deleteMessageOk: true,
      });

      return;
    }

    CourseService.updatePlanSubscription(tabData, (response) => {
      if (response) {
        if (response.success) {
          if (this.state.planIds.length < 1) {
            this.setState({
              deleteMessageContent: "Please select a subscription plan",
              deleteMessage: true,
            });
            return;
          }

          this.setState({
            message: "The subscription plan has been associated successfully",
            success: true,
            planIds: [],
            selectedCourseId: "",
          });

          let inputs2 = document.querySelectorAll("#search-field");
          for (let i = 0; i < inputs2.length; i++) {
            inputs2[i].checked = false;
          }
          this.retrieveCourse();
        }

        let inputs1 = document.querySelectorAll("#search-field-plan");
        for (let i = 0; i < inputs1.length; i++) {
          inputs1[i].checked = false;
        }
      }

      /*   this.setState({
                      currentSession: data,

                    }); */
    });
  };

  // removePlan = (e) => {
  //   const IdPlan = this.state.planIdToDelete;
  //   console.log(IdPlan);

  //   let tabData = {
  //     course_id: this.state.courseIdToRemove,
  //     subscription_plan_ids: IdPlan,
  //   };
   
  //   CourseService.deletePlan(tabData, (response) => {
  //     if (response) {
  //       if (response.success) {
  //         this.setState({
  //           message: "Subscription plan has been removed successfully.",
  //           delete: true,
  //           courseIdToRemove: "",
  //           planIdToDelete: "",
  //         });
  //         this.retrieveCourse();
  //       }
  //     }
  //   });
  // };

  
  removePlan = (e) => {
    const IdPlan = this.state.planIdToDelete;
    console.log(IdPlan);

    let tabData = {
        course_id: this.state.courseIdToRemove,
        subscription_plan_ids: IdPlan,
    };

    CourseService.deletePlan(tabData, (response) => {
      console.log("DELETE PLAN")
        if (response) {
            if (response.success) {
                this.setState({
                    message: "Subscription plan has been removed successfully.",
                    delete: true,
                    courseIdToRemove: "",
                    planIdToDelete: "",
                });
                this.retrieveCourse();
            } else {
              console.log("DELETE PLAN")
                // Gérer le cas où la réponse n'est pas réussie
                this.setState({
                    message: "Failed to remove subscription plan.",
                    delete: false,
                });
            }
        } else {
          console.log("No response from the server")
            // Gérer le cas où la réponse est indéfinie ou null
            this.setState({
                // message: "No response from the server.",
                deleteMessageContent: "No response from the server.",
                deleteMessageOk: true,
            });
        }
    });
};
  savePlanIdToRemove = (e) => {
    const idCourse = e.target.getAttribute("value");
    const idPlan = e.target.getAttribute("id");

    if (!this.state.planIdToDelete.includes(idPlan)) {
      this.setState({ planIdToDelete: [...this.state.planIdToDelete, idPlan] });
    } else {
      let tabId = this.state.planIdToDelete.filter((e) => e !== idPlan);
      this.setState({ planIdToDelete: tabId });
    }
    this.setState({ courseIdToRemove: idCourse });
  };

  retrievePlan = () => {
    SubscriptionPlanService.getAll((data) => {
      this.setState({ plans: data });
    });
  };

  handleChangePlan = (event) => {
    let planId = event.target.value.toString();
    if (!this.state.planIds.includes(planId)) {
      this.setState({ planIds: [...this.state.planIds, planId] });
    } else {
      let tabId = this.state.planIds.filter((e) => e !== planId);
      this.setState({ planIds: tabId });
    }
  };

  //Manage course

  openListSubscription = (e) => {
    const id = e.target.getAttribute("id");
    console.log("the selected course is: " + id);

    const navigate = this.props.myNavigate;
    //alert(navigate);
    navigate({
      pathname: "/userstransaction",
      search: "?courseId=" + id,
    });
  };

  //Apply settings

  applySettings = () => {
    /*  if (this.state.sessionIds.length > 1) {
           if (this.state.isCurrentSessionSetting) {
             this.setState({
               deleteMessageContent: "Impossible to set many session as current.",
               deleteMessage: true,
             });
             return;
           }
         } */

    /* if (this.state.sessionIds.length < 1) {
          this.setState({
            deleteMessageContent: "Please select a session.",
            deleteMessage: true,
          });
          return;
        } */

    //  if(this.state.sessionSubscriptionStatusSetting == "" || this.state.sessionPublicationStatusSetting == ""){
    //   this.setState({
    //     deleteMessageContent: "Make sure you have selected the subscription status and the publication status.",
    //     deleteMessage: true,
    //   });
    //   return;
    // };
    this.settingsEmpty();
    /*  if (this.state.courseIds.length < 1) {
      this.setState({
          deleteMessageContent: "Please select a course.",
          deleteMessage: true,
      });
      return;
  } */

    const data = {
      course_ids: this.state.courseIds,
      /* openStatus: this.state.courseSubscriptionStatusSetting,
      publicationStatus: this.state.coursePublicationStatusSetting, */
      subscription_status: this.state.SubscriptionStatusSetting,
      publication_status: this.state.PublicationStatusSetting,
      //session_progression_status: this.state.sessionProgressionStatus,
    };

    CourseService.updateCourseSettings(data, (response) => {
      if (response) {
        if (response.success) {
          this.cancelSetting();
          this.retrieveCourse();
          this.setState({
            message: "Course successfully updated.",
            successMessage: true,
          });
        } else {
          this.setState({
            deleteMessageContent: response.message,
            deleteMessage: true,
          });
        }
      }
      let inputs2 = document.querySelectorAll("#search-field");
      for (let i = 0; i < inputs2.length; i++) {
        inputs2[i].checked = false;
      }
    });
  };

  handleChangeCategory = (event) => {
    let courseId = event.target.value.toString();
    if (!this.state.courseIds.includes(courseId)) {
      this.setState({ courseIds: [...this.state.courseIds, courseId] });
    } else {
      let tabCourse = this.state.courseIds.filter((e) => e !== courseId);
      this.setState({ courseIds: tabCourse });
    }
  };

  planStop = () => {
    let button = document.getElementById("search-field-plan");
    if (this.state.planIds.length < 1) {
      document.getElementById("search-field-plan");
      return;
      /* window.alert('please choose a plan'); */
    }
  };

  settingsEmpty = () => {
    if (this.state.courseIds.length < 1) {
      this.setState({
        deleteMessageContent: "Please select a course.",
        deleteMessageOk: true,
      });
      document.getElementById("stop");
      /*    window.alert('please choose a plan');
       */ return;
    }
  };


  render() {
    const { currentCourse, selectedResource } = this.state;

    const handlePageClick = (page) => {
      let index = page.selected + 1;
      CourseService.GetAllSimpleCourses(
        index,
        this.state.itemPerPage,
        (response) => {
          this.setState({
            courses: response.data,
            lastPage: response.total_pages,
          });
        }
      );
    };

    const subscriptionStatusOpen = {
      open: "OPENED",
      close: "CLOSED",
    };

    const publicationClose = {
      published: "PUBLISHED",
      unpublished: "UNPUBLISHED",
    };

    return (
      <div className="g-sidenav-show  bg-gray-200">
        <Sidebar />
        <main className="mainAdmin r ms-100">
          <AdminHeader location="Courses" />
          {this.state.firstLoader ? (
            <center>
              <div
                className="spinner-border"
                role="status"
                style={{
                  width: "100px",
                  height: "100px",
                  marginTop: "20%",
                }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            </center>
          ) : (
            <>
              <div className="row  mb-7">
                <div className="col-12">
                  <nav
                    className="navbar navbar-expand-lg blur border-radius-lg top-0
                                    z-index-3 shadow position-absolute mt-4 py-2 start-0 end-0 mx-4"
                  >
                    <div className="container-fluid ps-2 pe-0">
                      <a
                        className="navbar-brand font-weight-bolder ms-lg-0 ms-3 "
                        href="app/src/components/Admin/session/Session#"
                      >
                        Course configuration panel
                      </a>
                      <button
                        className="navbar-toggler shadow-none ms-2"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navigation2"
                        aria-controls="navigation"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={() =>
                          this.setState({
                            showConfigurationPanel:
                              !this.state.showConfigurationPanel,
                          })
                        }
                      >
                        {!this.state.showConfigurationPanel ? "open" : "close"}
                      </button>
                      <div
                        className="collapse navbar-collapse"
                        id="navigation2"
                      >
                        <ul className="navbar-nav mx-auto">
                          <li className="nav-item">
                            <a
                              href="javascript:;"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              <button
                                type="button"
                                className="btn btn-secondary"
                                style={{ marginBottom: "6%", marginTop: "6%" }}
                              >
                                New course
                              </button>
                            </a>
                          </li>
                        </ul>
                        <ul className="navbar-nav d-lg-block d-none">
                          <li className="nav-item">
                            <span
                              className="material-icons"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.setState({
                                  showConfigurationPanel:
                                    !this.state.showConfigurationPanel,
                                })
                              }
                            >
                              {!this.state.showConfigurationPanel
                                ? "expand_more"
                                : "expand_less"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>

              {this.state.showConfigurationPanel ? (
                <div className="row">
                  <div className="col-lg-12 col-md-10 mx-auto">
                    <div className="card ">
                      <div className="card-header p-3">
                        <div>
                          <h5>Settings</h5>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <select
                              className="form-control"
                              name="openStatus"
                              value={this.state.SubscriptionStatusSetting}
                              onChange={(e) =>
                                this.setState({
                                  SubscriptionStatusSetting: e.target.value,
                                })
                              }
                            >
                              <option value="">--Subscription status--</option>
                              <option value="1">
                                {subscriptionStatusOpen.open}
                              </option>
                              <option value="0">
                                {subscriptionStatusOpen.close}
                              </option>
                            </select>
                          </div>
                          <div className="col-md-4">
                            <select
                              className="form-control"
                              name="publicationStatus"
                              value={this.state.PublicationStatusSetting}
                              onChange={(e) =>
                                this.setState({
                                  PublicationStatusSetting: e.target.value,
                                })
                              }
                            >
                              <option value="">--Publication Status--</option>
                              <option value="1">
                                {publicationClose.published}
                              </option>
                              <option value="0">
                                {publicationClose.unpublished}
                              </option>
                            </select>
                          </div>
                          <div className="col-md-4">
                            <button
                              id="stop"
                              type="button"
                              className="btn btn-primary"
                              onClick={this.applySettings}
                            >
                              Apply settings
                            </button>
                            &nbsp;&nbsp;
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={this.cancelSetting}
                            >
                              Cancel
                            </button>
                          </div>

                        </div>
                        <div>
                          <hr />
                          <h5>Filter</h5>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <select
                              className="form-control"
                              name="courseSubscriptionStatus"
                              value={this.state.courseSubscriptionStatusFilter}
                              onChange={(e) =>
                                this.setState({
                                  courseSubscriptionStatusFilter:
                                    e.target.value,
                                })
                              }
                            >
                              <option value="">--Subscription status--</option>
                              <option value="1">OPENED</option>
                              <option value="0">CLOSED</option>
                            </select>
                          </div>
                          <div className="col-md-4">
                            <select
                              className="form-control"
                              name="coursePublicationStatus"
                              value={this.state.coursePublicationStatusFilter}
                              onChange={(e) =>
                                this.setState({
                                  coursePublicationStatusFilter: e.target.value,
                                })
                              }
                            >
                              <option value="">--Publication Status--</option>
                              <option value="1">PUBLISHED</option>
                              <option value="0">UNPUBLISHED</option>
                            </select>
                          </div>

                         
                          <div className="col-md-4">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={this.GetCourseFilter}
                              // onClick={filter}
                            >
                              Apply filter
                            </button>
                            &nbsp;&nbsp;
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={this.cancelFilter}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="container-fluid py-4">
                <div className="row mb-4">
                  <div className="col-lg-12 col-md-6 mb-md-0 mb-4">
                    <div className="card">
                      <div className="card-header pb-0">
                        <div className="row">
                          <div className="col-lg-6 col-5 my-auto text-end">
                            <div className="dropdown float-lg-end pe-4">
                              <div
                                className="modal fade"
                                id="exampleModal"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog modal-dialog-centered">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="exampleModalLabel"
                                      >
                                        Create a new course
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div className="modal-body">
                                      <form
                                        action="app/src/components/Admin/Admin course/AdminCourses#"
                                        id="create-session-form"
                                      >
                                        <div className="row">
                                          <div className="col-md-3 center mt-5">
                                            Name
                                            <span className="red" style={{ color: "red" }}>*</span>
                                          </div>
                                          <div className="col-md-9">
                                            <div className="single-form">
                                              <input
                                                id="valide"
                                                type="text"
                                                name="name"
                                                placeholder="Name"
                                                onChange={this.handleChange}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-3 center mt-5">
                                            Description
                                            <span className="red" style={{ color: "red" }}>*</span>
                                          </div>
                                          <div className="col-md-9">
                                            <div className="single-form">
                                              <input
                                                  id="valide"
                                                  type="text"
                                                  name="description"
                                                  placeholder="Description"
                                                  onChange={this.handleChange}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-3 center mt-5">
                                            WP GROUP ID
                                          </div>
                                          <div className="col-md-9">
                                            <div className="single-form">
                                              <input
                                                type="number"
                                                placeholder="WP GROUP ID"
                                                name="wordpressGroupId"
                                                onChange={this.handleChange}
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-md-3 center mt-5">
                                            MD GROUP ID
                                          </div>
                                          <div className="col-md-9">
                                            <div className="single-form">
                                              <input
                                                type="number"
                                                placeholder="MD GROUP ID"
                                                name="moodleGroupId"
                                                onChange={this.handleChange}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {/*   <div className="row">
                                      <div className="col-md-3 center mt-5">
                                        End Date date{" "}
                                        <span className="red">*</span>
                                      </div>
                                      <div className="col-md-9">
                                        <div className="single-form">
                                          <input
                                            type="date"
                                            name="endDate"
                                            placeholder="Start Date"
                                            onChange={this.handleChange}
                                          />
                                        </div>
                                      </div>
                                    </div>*/}

                                        <div className="row">
                                          <div className="col-md-3 center mt-5">
                                            Image
                                            {/*<span className="red">*</span>*/}
                                          </div>
                                          <div className="col-md-9 center mt-5">
                                            <input
                                              id="valide"
                                              type="file"
                                              name="image"
                                              className="form-control"
                                              width="20px"
                                              onChange={this.handleUploadImage}
                                            />
                                          </div>
                                        </div>                                       

                                        <div className="row">
                                          <div className="col-md-3 center mt-5">
                                            Publication Status
                                          </div>
                                          <div className="col-md-9 center mt-5">
                                            <select
                                              className="form-control"
                                              name="coursePublicationStatus"
                                              value={
                                                this.state
                                                  .coursePublicationStatus
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  coursePublicationStatus:
                                                    e.target.value,
                                                })
                                              }
                                            >
                                              <option value="">
                                                --Publication Status--
                                              </option>
                                              <option value="1">
                                                {publicationClose.published}
                                              </option>
                                              <option value="0">
                                                {publicationClose.unpublished}
                                              </option>
                                            </select>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-md-3 center mt-5">
                                            Subscription status
                                          </div>
                                          <div className="col-md-9 center mt-5">
                                            <select
                                              className="form-control"
                                              name="courseSubscriptionStatus"
                                              value={this.state.courseStatus}
                                              onChange={(e) =>
                                                this.setState({
                                                  courseStatus: e.target.value,
                                                })
                                              }
                                            >
                                              <option value="">
                                                --Subscription status--
                                              </option>
                                              <option value="1">
                                                {" "}
                                                {subscriptionStatusOpen.open}
                                              </option>
                                              <option value="0">
                                                {" "}
                                                {subscriptionStatusOpen.close}
                                              </option>
                                            </select>
                                          </div>
                                        </div>

                                        <div className="single-form">
                                          <button
                                            id="button"
                                            type="button"
                                            className="btn btn-success btn-lg btn-block"
                                            onClick={this.saveCourse}
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            Add
                                          </button>
                                        </div>
                                      </form>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="modal fade"
                        id="exampleModalAdd"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Associated Courses
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <table className="table table-borderless">
                                <thead>
                                  <tr>
                                    <th>...</th>
                                    <th>Name</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {this.state.resources &&
                                    this.state.resources.map((resource) => (
                                      <tr>
                                        <td
                                          value={
                                            "/api/resources/" + resource.id
                                          }
                                          key={resource.id}
                                        >
                                          {resource.id}
                                        </td>

                                        <td>{resource.title}</td>
                                        <td>
                                          <button
                                            value={
                                              "/api/resources/" + resource.id
                                            }
                                            className="badge badge-sm bg-gradient-success"
                                            onClick={this.sendData}
                                          >
                                            Associate
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn bg-gradient-success"
                                data-bs-dismiss="modal"
                                id="search-Field"
                                onClick={this.sendPlanData}
                              >
                                Associate
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="position-fixed bottom-1 end-1 z-index-2">
                        {this.state.deleteMessage ? (
                          <div
                            className="toast fade show p-2 mt-2 bg-gradient-danger"
                            role="alert"
                            aria-live="assertive"
                            id="infoToast"
                            aria-atomic="true"
                          >
                            <div className="toast-header bg-transparent border-0">
                              <i className="material-icons text-white me-2">
                                check
                              </i>
                              <span className="me-auto text-white font-weight-bold">
                                course{" "}
                              </span>
                              <small className="text-white">just now</small>
                              <i
                                className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                data-bs-dismiss="toast"
                                aria-label="Close"
                                onClick={(e) =>
                                  this.setState({ deleteMessage: false })
                                }
                              ></i>
                            </div>
                            <hr className="horizontal light m-0" />
                            <div className="toast-body text-white">
                              {this.state.deleteMessageContent}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        {this.state.updateMessage ? (
                          <div
                            className="toast fade show p-2 mt-2 bg-white"
                            role="alert"
                            aria-live="assertive"
                            id="warningToast"
                            aria-atomic="true"
                          >
                            <div className="toast-header border-0">
                              <i className="material-icons text-warning me-2">
                                check
                              </i>
                              <span className="me-auto font-weight-bold">
                                Course
                              </span>
                              <small className="text-body">just now</small>
                              <i
                                className="fas fa-times text-md ms-3 cursor-pointer"
                                data-bs-dismiss="toast"
                                aria-label="Close"
                                onClick={(e) =>
                                  this.setState({ updateMessage: false })
                                }
                              ></i>
                            </div>
                            <hr className="horizontal dark m-0" />
                            <div className="toast-body">
                              Course successfully updated.
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        {this.state.successMessage ? (
                          <div
                            className="toast fade show p-2 mt-2 bg-gradient-info"
                            role="alert"
                            aria-live="assertive"
                            id="infoToast"
                            aria-atomic="true"
                          >
                            <div className="toast-header bg-transparent border-0">
                              <i className="material-icons text-white me-2">
                                check
                              </i>
                              <span className="me-auto text-white font-weight-bold">
                                course
                              </span>
                              <small className="text-white">just now</small>
                              <i
                                className="fas fa-times text-md ms-3 cursor-pointer"
                                data-bs-dismiss="toast"
                                aria-label="Close"
                                onClick={(e) =>
                                  this.setState({ successMessage: false })
                                }
                              ></i>
                            </div>
                            <hr className="horizontal light m-0" />
                            <div className="toast-body text-white ">
                              {this.state.message}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        {this.state.success ? (
                          <div
                            className="toast fade show p-2 mt-2 bg-gradient-info"
                            role="alert"
                            aria-live="assertive"
                            id="infoToast"
                            aria-atomic="true"
                          >
                            <div className="toast-header bg-transparent border-0">
                              <i className="material-icons text-white me-2">
                                check
                              </i>
                              <span className="me-auto text-white font-weight-bold">
                                course{" "}
                              </span>
                              <small className="text-white">just now</small>
                              <i
                                className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                data-bs-dismiss="toast"
                                aria-label="Close"
                                onClick={(e) =>
                                  this.setState({ success: false })
                                }
                              ></i>
                            </div>
                            <hr className="horizontal light m-0" />
                            <div className="toast-body text-white">
                              {this.state.message}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        {this.state.deleteMessageOk ? (
                          <div
                            className="toast fade show p-2 mt-2 bg-gradient-danger"
                            role="alert"
                            aria-live="assertive"
                            id="infoToast"
                            aria-atomic="true"
                          >
                            <div className="toast-header bg-transparent border-0">
                              <i className="material-icons text-white me-2">
                                check
                              </i>
                              <span className="me-auto text-white font-weight-bold">
                                course{" "}
                              </span>
                              <small className="text-white">just now</small>
                              <i
                                className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                data-bs-dismiss="toast"
                                aria-label="Close"
                                onClick={(e) =>
                                  this.setState({ deleteMessageOk: false })
                                }
                              ></i>
                            </div>
                            <hr className="horizontal light m-0" />
                            <div className="toast-body text-white">
                              {this.state.deleteMessageContent}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        {this.state.delete ? (
                          <div
                            className="toast fade show p-2 mt-2 bg-gradient-info"
                            role="alert"
                            aria-live="assertive"
                            id="infoToast"
                            aria-atomic="true"
                          >
                            <div className="toast-header bg-transparent border-0">
                              <i className="material-icons text-white me-2">
                                check
                              </i>
                              <span className="me-auto text-white font-weight-bold">
                                course
                              </span>
                              <small className="text-white">just now</small>
                              <i
                                className="fas fa-times text-md ms-3 cursor-pointer"
                                data-bs-dismiss="toast"
                                aria-label="Close"
                                onClick={(e) =>
                                  this.setState({ delete: false })
                                }
                              ></i>
                            </div>
                            <hr className="horizontal light m-0" />
                            <div className="toast-body text-white ">
                              {this.state.message}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                  

                        {/* Modal Apply filter */}

                        
                      </div>
                      <div className="table-responsive p-0">
                        <table className="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                ...
                              </th>
                              {/* <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Title
                          </th> */}

                              <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Name
                              </th>

                              <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                WP GROUP ID
                              </th>

                              <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                MD GROUP ID
                              </th>

                              <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Description
                              </th>

                              <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Created At
                              </th>

                              {/*<th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">*/}
                              {/*  End date*/}
                              {/*</th>*/}

                              <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Course status
                              </th>

                              <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Subscription plan
                              </th>

                              <th className="text-secondary opacity-7">...</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.Loading ? (
                              <div
                                className="spinner-border text-primary"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <></>
                            )}
                            {this.state.courses &&
                              this.state.courses.map((course) => (
                                <tr key={course.id}>
                                  <td>
                                    <div className="d-flex px-2 py-1">
                                      <input
                                        type="checkbox"
                                        id="search-field"
                                        value={course.id}
                                        onClick={this.handleChangeCategory}
                                      />
                                    </div>
                                  </td>

                                  <td className="align-middle text-center text-sm">
                                    <span
                                      className="text-xs font-weight-bold"
                                      id={course.id}
                                      onClick={this.openDetails}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {" "}
                                      {course.name}{" "}
                                    </span>
                                  </td>

                                  <td className="align-middle text-center text-sm">
                                    <span className="text-xs font-weight-bold">
                                      {" "}
                                      {course.wordpressGroupId}{" "}
                                    </span>
                                  </td>

                                  <td className="align-middle text-center text-sm">
                                    <span className="text-xs font-weight-bold">
                                      {" "}
                                      {course.moodleGroupId}{" "}
                                    </span>
                                  </td>

                                  <td className="align-middle text-center text-sm">
                                    <span className="text-xs font-weight-bold">
                                      {" "}
                                      {course.description}{" "}
                                    </span>
                                  </td>

                                  <td className="align-middle text-center text-sm">
                                    <p className="text-xs font-weight-bold mb-0">
                                      {course.created_at
                                        ? moment(course.created_at)
                                            .utc()
                                            .format("DD-MM-YYYY")
                                        : "-"}
                                    </p>
                                  </td>

                                  {/*<td className="align-middle text-center text-sm">*/}
                                  {/*  <p className="text-xs font-weight-bold mb-0">*/}
                                  {/*    {moment(course.endDate)*/}
                                  {/*      .utc()*/}
                                  {/*      .format("YYYY-MM-DD")}*/}
                                  {/*  </p>*/}
                                  {/*  <p className="text-xs text-secondary mb-0">Organization</p>*/}
                                  {/*</td>*/}

                                  <td className="align-middle text-center">
                                    <div>
                                      {course.publication_status ===
                                      "PUBLISHED" ? (
                                        <span className="badge badge-sm bg-gradient-success text-center">
                                          PUBLISHED
                                        </span>
                                      ) : (
                                        <></>
                                      )}

                                      {course.publication_status ===
                                      "UNPUBLISHED" ? (
                                        <span className="badge badge-sm bg-gradient-warning text-center">
                                          UNPUBLISHED
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div>
                                      {course.status === "OPENED" ? (
                                        <span className="badge badge-sm bg-gradient-success text-center">
                                          OPENED
                                        </span>
                                      ) : (
                                        <></>
                                      )}

                                      {course.status === "CLOSED" ? (
                                        <span className="badge badge-sm bg-gradient-danger text-center">
                                          CLOSED
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                      {course.status === "" ? <></> : <></>}
                                    </div>
                                  </td>

                                  <td className="align-middle text-center">
                                    {course.subscriptionPlans &&
                                      course.subscriptionPlans.map((plan) => (
                                        <div key={plan.id}>
                                          <span className="badge badge-sm bg-gradient-primary">
                                            {plan.name}
                                          </span>

                                          <span
                                            className="material-icons danger"
                                            id={plan.subscription_plan_id}
                                            value={course.id}
                                            onClick={this.savePlanIdToRemove}
                                            data-bs-target="#confirmationRemoveModal"
                                            data-bs-toggle="modal"
                                            style={{
                                              cursor: "pointer",
                                              color: "firebrick",
                                            }}
                                          >
                                            cancel
                                          </span>

                                          <br />
                                        </div>
                                      ))}
                                    <a
                                      href="javascript:;"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModalAddPlan"
                                    >
                                      <p
                                        className="badge badge-sm bg-gradient-success"
                                        id={course.id}
                                        onClick={this.selectedCourseById}
                                      >
                                        Add
                                      </p>
                                    </a>
                                  </td>

                                  <td className="text-center">
                                    <div className="d-flex px-2 py-1">
                                      <div className="d-flex flex-column justify-content-center">
                                        <a
                                          className="text-dark"
                                          href="javascript:;"
                                          data-bs-toggle="modal"
                                          data-bs-target={
                                            "#exampleModal" + course.id
                                          }
                                        >
                                          <span
                                            className="material-icons"
                                            onClick={this.getCourseByUrl}
                                            id={course.id}
                                            style={{ color: "dodgerblue" }}
                                          >
                                            edit
                                          </span>
                                        </a>

                                        <span
                                          className="material-icons"
                                          style={{
                                            cursor: "pointer",
                                            color: "firebrick",
                                          }}
                                          onClick={this.saveCourseIdToRemove}
                                          data-bs-target="#confirmationDeleteSessionModal"
                                          data-bs-toggle="modal"
                                          id={course.id}
                                        >
                                          delete
                                        </span>

                                        <span
                                          className="material-icons danger"
                                          id={course.id}
                                          onClick={this.openDetails}
                                          style={{
                                            cursor: "pointer",
                                            color: "dodgerblue",
                                          }}
                                        >
                                          visibility
                                        </span>

                                        <button
                                          type="button"
                                          className="btn btn-secondary"
                                          id={course.id}
                                          onClick={this.openListSubscription}
                                        >
                                          Manage
                                        </button>
                                      </div>
                                    </div>

                                    <div
                                      className="modal fade"
                                      id="confirmationDeleteSessionModal"
                                      tabIndex="-1"
                                      aria-labelledby="confirmationDeleteSessionModal"
                                      aria-hidden="true"
                                    >
                                      <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h5
                                              className="modal-title"
                                              id="exampleModalLabel"
                                            >
                                              Delete course
                                            </h5>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body">
                                            Do you want to delete the selected
                                            course ?
                                          </div>
                                          <div className="modal-footer">
                                            <button
                                              type="button"
                                              className="btn btn-danger  btn-block"
                                              onClick={this.delete}
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              Confirm
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-secondary btn-block"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="modal fade"
                                      id={"exampleModal" + course.id}
                                      tabIndex="-1"
                                      aria-labelledby="exampleModalLabel"
                                      aria-hidden="true"
                                    >
                                      <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h5
                                              className="modal-title"
                                              id="exampleModalLabel"
                                            >
                                              Edit Course
                                            </h5>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <form
                                            action="app/src/components/Admin/Admin course/AdminCourses#"
                                            id="update-session-form"
                                          >
                                            {/*  <div className="single-form">
                                              <input
                                                type="text"
                                                placeholder="Name"
                                                name="name"
                                                value={currentCourse.name}
                                                onChange={this.onNameChange}
                                              />
                                            </div> */}

                                            <div className="row">
                                              <div className="col-md-3 center mt-5">
                                                Name
                                                <span className="red">*</span>
                                              </div>
                                              <div className="col-md-9">
                                                <div className="single-form">
                                                  <input
                                                    type="text"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={currentCourse.name}
                                                    onChange={this.onNameChange}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-md-3 center mt-5">
                                                Description
                                                <span className="red">*</span>
                                              </div>
                                              <div className="col-md-9">
                                                <div className="single-form">
                                                  <input
                                                      type="text"
                                                      placeholder="Description"
                                                      name="description"
                                                      value={
                                                        currentCourse.description
                                                      }
                                                      onChange={
                                                        this.onDescriptionChange
                                                      }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-md-3 mt-5">
                                                WP GROUP ID
                                              </div>
                                              <div className="col-md-9">
                                                <div className="single-form">
                                                  <input
                                                    type="number"
                                                    placeholder="WP GROUP ID"
                                                    name="wordpressGroupId"
                                                    onChange={
                                                      this.onWordpressCourseIdChange
                                                    }
                                                    value={
                                                      currentCourse.wordpressGroupId
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>

                                            <div className="row">
                                              <div className="col-md-3 center mt-5">
                                                MD GROUP ID
                                              </div>
                                              <div className="col-md-9">
                                                <div className="single-form">
                                                  <input
                                                    type="number"
                                                    placeholder="MD GROUP ID"
                                                    name="moodleGroupId"
                                                    onChange={
                                                      this
                                                        .onMoodleCourseIdChange
                                                    }
                                                    value={
                                                      currentCourse.moodleGroupId
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>

                                            {/*  <div className="row">
                                                                                        <div className="col-md-3 center mt-5">
                                                                                           Created At
                                                                                        </div>
                                                                                        <div className="col-md-9">
                                                                                            <div className="single-form">
                                                                                                <input
                                                                                                    type="date"
                                                                                                    name="createdAt"
                                                                                                    placeholder="Created At"
                                                                                                    onChange={
                                                                                                        this.onCreatedAtChange
                                                                                                    }
                                                                                                    value={moment(
                                                                                                        currentCourse.createdAt
                                                                                                    ).format("YYYY-MM-DD")}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>  */}

                                            <div className="row">
                                              <div className="col-md-3 center mt-5">
                                                Image
                                              </div>
                                              <div className="col-md-9">
                                                <div className="single-form">
                                                  <input
                                                    type="file"
                                                    name="image"
                                                    onChange={this.onFileChange}
                                                  />
                                                </div>
                                              </div>
                                            </div>

                                            {/*   <div className="row">
                                                                                        <div className="col-md-3 center mt-5">
                                                                                            Start date
                                                                                        </div>
                                                                                        <div className="col-md-9">
                                                                                            <div className="single-form">
                                                                                                <input
                                                                                                    type="date"
                                                                                                    name="startDate"
                                                                                                    placeholder="Start Date"
                                                                                                    onChange={
                                                                                                        this.onStartDateChange
                                                                                                    }
                                                                                                    value={moment(
                                                                                                        currentCourse.startDate
                                                                                                    ).format("YYYY-MM-DD")}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-md-3 center mt-5">
                                                                                            End date
                                                                                        </div>
                                                                                        <div className="col-md-9">
                                                                                            <div className="single-form">
                                                                                                <input
                                                                                                    type="date"
                                                                                                    name="endDate"
                                                                                                    placeholder="End Date"
                                                                                                    onChange={this.onEndDateChange}
                                                                                                    value={moment(
                                                                                                        currentCourse.endDate
                                                                                                    ).format("YYYY-MM-DD")}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>*/}
                                            <div className="row">
                                              <div className="col-md-3 center mt-5">
                                                Publication Status
                                              </div>
                                              <div className="col-md-9 center mt-5">
                                                <select
                                                  className="form-control"
                                                  name="coursePublicationStatus"
                                                  value={
                                                    currentCourse.publication_status
                                                  }
                                                  onChange={
                                                    this
                                                      .onCoursePublicationStatusChange
                                                  }
                                                >
                                                  <option>
                                                    --Publication Status--
                                                  </option>
                                                  <option value="PUBLISHED">
                                                    {publicationClose.published}
                                                  </option>
                                                  <option value="UNPUBLISHED">
                                                    {
                                                      publicationClose.unpublished
                                                    }
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-md-3 center mt-5">
                                                Subscription status
                                              </div>
                                              <div className="col-md-9 center mt-5">
                                                <select
                                                  className="form-control"
                                                  name="courseStatus"
                                                  value={currentCourse.status}
                                                  onChange={
                                                    this.onCourseStatusChange
                                                  }
                                                >
                                                  <option value="">
                                                    --Subscription status--
                                                  </option>

                                                  <option value="OPENED">
                                                    {
                                                      subscriptionStatusOpen.open
                                                    }
                                                  </option>
                                                  <option value="CLOSED">
                                                    {
                                                      subscriptionStatusOpen.close
                                                    }
                                                  </option>
                                                </select>
                                              </div>
                                            </div>

                                            <div className="single-form">
                                              <button
                                                type="button"
                                                className="btn btn-success btn-lg btn-block"
                                                onClick={this.updateCourse}
                                                id={course.id}
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                              >
                                                Update
                                              </button>
                                            </div>
                                          </form>

                                          <div className="modal-footer">
                                            <button
                                              type="button"
                                              className="btn btn-secondary"
                                              data-bs-dismiss="modal"
                                            >
                                              Close
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="exampleModalAddPlan"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Associated Plans
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th>...</th>
                              <th>Name</th>
                            </tr>
                          </thead>

                          <tbody>
                            {this.state.plansToShow &&
                              this.state.plansToShow.map((plan) => (
                                <tr key={plan.id}>
                                  <td>
                                    <div className="d-flex px-2 py-1">
                                      <input
                                        type="checkbox"
                                        id="search-field-plan"
                                        value={plan.id}
                                        onClick={this.handleChangePlan}
                                      />
                                    </div>
                                  </td>
                                  <td>{plan.name}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-sm btn-success"
                          data-bs-dismiss="modal"
                          id="search-field-plan"
                          onClick={this.sendPlanData}
                        >
                          Associate
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id={"confirmationRemoveModal"}
                  tabIndex="-1"
                  aria-labelledby="confirmationRemoveModal"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Dissociate subscription plan
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        Do you want to remove the selected subscription plan
                        from the course ?
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger  btn-block"
                          onClick={this.removePlan}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Confirm
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary btn-block"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-5 offset-5">
                    <div className="card-footer">
                      <nav aria-label="...">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={this.state.lastPage}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={6}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination justify-content-end mb-0"
                          }
                          pageClassName={"page-item"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          pageLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </main>
      </div>
    );
  }
}

export default function (props) {
  const navigation = useNavigate();
  //const {state} = useLocation();
  return <AdminCourses {...props} myNavigate={navigation} />;
}
