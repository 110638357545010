const baseUrl = 'https://jsonplaceholder.typicode.com/users';
const authenticationBaseUrl = process.env.REACT_APP_AUTHENTICATION_SERVICE;
const wordpressUrl = process.env.REACT_APP_WORDPRESS;
//const authenticationBaseUrl = 'http://dev.utrains.org:8080';
const authenticationBaseUrlSecure = process.env.REACT_APP_AUTHENTICATION_SERVICE + '/api/secure/';
const accountingBaseUrl = process.env.REACT_APP_ACCOUNTING_SERVICE + '/api';
const accountingBaseRootUrl = process.env.REACT_APP_ACCOUNTING_SERVICE;
export const accountingBaseUrlSecure = process.env.REACT_APP_ACCOUNTING_SERVICE + '/api/v1/secure/';
export const MEDIA_URL = authenticationBaseUrl + "/uploads/";
export const MEDIA_URL_ACCOUNTING = accountingBaseRootUrl + "/media";
export const MEDIA_URL_ACCOUNTING_AUTH_SETTINGS = accountingBaseRootUrl + "/media/auth-page-settings/";


const backendUrls = {
    baseUrl: baseUrl,
    Authentication: {
        register: authenticationBaseUrl + "/api/register",
        login: authenticationBaseUrl + "/api/login",
        logout: authenticationBaseUrlSecure + "user/logout",
        activateAccount: authenticationBaseUrl + "/api/activate/account",
        tryResetPassword: authenticationBaseUrl + "/api/account/password-reset",
        checkResetPasswordToken: authenticationBaseUrl + "/api/account/password-reset/check",
        resetPassword: authenticationBaseUrl + "/api/account/password-reset/change",
        resentActivationMail: authenticationBaseUrl + "/api/resend-activation-mail",
        userRefresh: authenticationBaseUrlSecure + "user/refresh",
        CONFIRM_ADMIN_IDENTITY: authenticationBaseUrl + "/api/check-otp",
        createManualUserAdministrationSide: authenticationBaseUrlSecure+ "admin/create/user",

    },
    Wordpress: {
        root: wordpressUrl,
        login: wordpressUrl + "/wp-login.php"
    },

    USER: {
        updateInformations: authenticationBaseUrlSecure + "user/update",
        updatePassword: authenticationBaseUrlSecure + "user/update-password",
        updateEmail: authenticationBaseUrl + "/api/user/confirm-new-email",
        getAllUsers: authenticationBaseUrlSecure + "admin/users",
        changeStatus: authenticationBaseUrlSecure + "admin/user/enable",
        changeRole: authenticationBaseUrlSecure + "admin/user/update-role",
        enableUserAccount: authenticationBaseUrlSecure + "admin/user/lock",
        GET_ONE: accountingBaseUrl + "/users",
        avatarChangeRequest: authenticationBaseUrlSecure + "user/upload-picture",
        contactus: authenticationBaseUrl + "/api/contact-us",
        minMax: accountingBaseUrlSecure + "user/subscription/min-max-amount",
        toPaySubscriptionList: accountingBaseUrlSecure + "user/unsettled-subscriptions",
        toPayPaymentIntent: accountingBaseUrlSecure + "user/subscription/initiate-payment-intent",
        confirmSuccessPayment: accountingBaseUrlSecure + "user/subscription/confirm-payment-intent",
        filterBySubscriptionPlan: accountingBaseUrlSecure + "accounting/session-info",
        showSubscription: accountingBaseUrlSecure + "accounting/session/list/subscriptions",
        showCourseSubscription: accountingBaseUrlSecure + "accounting/subscription_course",
        showStatistics: accountingBaseUrlSecure + "accounting/session/compute/statistics",
        subscriptionToConfirm: accountingBaseUrlSecure + "user/subscription-intent/confirm-incomplete",
        courseSubscriptionToConfirm: accountingBaseUrlSecure + "user/courses/subscription-intent/confirm-incomplete",
        checkSubscription: accountingBaseUrlSecure + "user/get-subscription",
        checkSubscriptionCourse: accountingBaseUrlSecure + "user/get-subscription-course",
        overview: accountingBaseUrlSecure + "user/overview",
        registerNewsletter: authenticationBaseUrl + "/api/newsletter",
        newsletterSubscribers: authenticationBaseUrlSecure + "admin/newsletter/subscribers",
        manualSubscription: accountingBaseUrl + "/v1/secure/user/manual-subscription",
        courseManualSubscription: accountingBaseUrl + "/v1/secure/accounting/subscription/create-manual",
        GET_ONE_AUTHENTICATION_USER: authenticationBaseUrlSecure + "admin/user",
        GET_OTP: authenticationBaseUrlSecure + "user/generate-otp",
        VERIFY_EXIST_WP_ACCOUNT: authenticationBaseUrl + "/api/wp/on-demand/account",
        getProfilUser: authenticationBaseUrlSecure + "admin/user",
        updateProfilUser: authenticationBaseUrlSecure + "admin/user/update-user-info",
        updatePasswordUser: authenticationBaseUrlSecure + "admin/user/update-user-password",
        invoice: accountingBaseUrlSecure + "user/invoice",
        courseInvoice: accountingBaseUrlSecure + "user/invoice-course",
        editSubscription: accountingBaseUrl + "/v1/secure/accounting/session/change",
        GET_ONE_AHTH_PAGE: accountingBaseUrl + "/v1/auth-page-setting",
        CREATE_NEW_IMAGE: accountingBaseUrl + "/v1/secure/accounting/auth-page-setting/create",
        UPDATE_REGISTER_IMAGE: accountingBaseUrl + "/v1/secure/accounting/auth-page-setting/update",
        DELETE: accountingBaseUrl + "/v1/secure/accounting/auth-page-setting/remove",
        GET_ALL_SESSION: accountingBaseUrlSecure + "user/get-subscription-information",
        GET_SUBSCRIBED_SESSION_INFO: accountingBaseUrlSecure + "user/get-information-to-pay-in-full",
        PAYMENT_DISOUNT: accountingBaseUrlSecure + "user/initiate-discount",
        CONFIRM_DISOUNT: accountingBaseUrlSecure + "user/discount-intent/confirm",
        SUCCESS_DISOUNT: accountingBaseUrlSecure + "user/information-about-success-subcription",
        GET_COUPON_CODE: accountingBaseUrlSecure + "user/handle-coupon",
        GET_ELIGIBILITY_COUPON: accountingBaseUrlSecure + "user/get-setting-user",


    },

    Stripe: {
        publicKey: accountingBaseUrl + "/stripe/public-key",
        paymentIntent: accountingBaseUrlSecure + "/user/stripe/payment-intent/create",
        requestSecretKey: accountingBaseUrlSecure + "user/credential/stripe/setup-intent/create",
        cardConfirmaton: accountingBaseUrlSecure + "user/stripe/payment-intent/create",
        updatePaymentMethod: accountingBaseUrlSecure + "user/credential/update/payment-method",
        getLastDigit: accountingBaseUrlSecure + "user/card/last4"
    },
    File: {
        imageLocation: accountingBaseRootUrl + "/media/"
    },
    Checkout: {
        createSubscriptionIntent: accountingBaseUrlSecure + "user/subscription-intent/create",
        confirmSubscriptionIntent: accountingBaseUrlSecure + "user/subscription-intent/confirm",
        createSubscriptionIntentCourse: accountingBaseUrlSecure + "user/courses/subscription-intent/create",
        confirmSubscriptionIntentCourse: accountingBaseUrlSecure + "user/courses/subscription-intent/confirm",
    },
    SessionPlan: {
        movie: accountingBaseUrl + "/movies",
        subscription_plans: accountingBaseUrl + "/subscription_plans",
        subscription: accountingBaseUrl + "/v1/subscription",
        currency: accountingBaseUrl + "/currencies",
        channel: accountingBaseUrl + "/channels",
        discount: accountingBaseUrl + "/discounts",
        GET_ONE: accountingBaseUrl + "/v1/secure/user/subscription-plan"

    },
    SESSION: {
        CREATE: accountingBaseUrlSecure + "accounting/session/create",
        GET_ALLs: accountingBaseUrlSecure + "accounting/session",
        GET_ALL: accountingBaseUrlSecure + "accounting/session",
        PAGINATE: accountingBaseUrlSecure + "accounting/session/filter",
        DELETE: accountingBaseUrlSecure + "accounting/session/remove",
        DELETE_DELAY: accountingBaseUrlSecure + "accounting/delete-attemps-to-postpone-payment",
        DELETE_DISCOUNT: accountingBaseUrlSecure + "accounting/delete-epired-at",
        DELETE_COUPON : accountingBaseUrlSecure + "accounting/delete-coupon", 
        UPDATE: accountingBaseUrlSecure + "accounting/session/update",
        ATRIPLAN: accountingBaseUrlSecure + "accounting/session/add/subscription-plan",
        DELPLAN: accountingBaseUrlSecure + "accounting/session/delete/subscription-plan",
        GET_ONE: accountingBaseUrl + "/sessions",
        GET_ONE_UNSECURE: accountingBaseUrl + "/v1/subscription-plan-and-course",
        GET_SESSION_USER_SPACE: accountingBaseUrl + "/v1/secure/user/subscription-plan-and-course",
        GET_NEXT_ONE: accountingBaseUrl + "/v1/lastSession",
        //GET_USER_SUBSCRIPTION: accountingBaseUrlSecure + "user/subscriptionByUser",
        GET_ALL_OPEN_SESSION: accountingBaseUrlSecure + "user/session/all-opened-sessions",
        GET_USER_SUBSCRIPTION: accountingBaseUrlSecure + "user/subscriptions",
        //GET_USER_SUBSCRIPTION: accountingBaseUrlSecure + "user/get-subscription-information",
        GET_ALL_SESSION_DATE_PROMOTION: accountingBaseUrlSecure + "user/session/all-promotion",
        GET_ALL_OPENED_SESSION: accountingBaseUrlSecure + "user/session/all-opened-sessions",
        GET_ALL_UNSECURED_OPENED_SESSION: accountingBaseUrl + "/v1/unsecure/session",
        GET_ONE_OPENED_SESSION: accountingBaseUrlSecure + "user/session/one-opened-session",
        GET_ONE_UNSECURED_OPENED_SESSION: accountingBaseUrl + "/v1/unsecure/session/one-opened-session",
        GET_SETTINGS_COUPON:accountingBaseUrl + "/v1/secure/accounting/create-or-update-setting",
        GET_ALL_USER_OF_SESSION: accountingBaseUrlSecure + "accounting/sessions",
        GET_ALL_USER_OF_SESSION_HAVING_PAID: accountingBaseUrlSecure + "accounting/session-user-having-paid",
        GET_ALL_SUBSCRIPTION_INTENT: accountingBaseUrlSecure + "accounting/subscription-intent",
        SEARCH_SUBSCRIPTION_INTENT: accountingBaseUrlSecure + "accounting/search-subscription-intent",
        GET_ALL_USER_OF_SESSION_NOT_HAVING_PAID: accountingBaseUrlSecure + "accounting/session-user-not-having-paid",
        GET_CURRENT_USER_SESSION_SECURE: accountingBaseUrlSecure + "user/current-session",
        GET_CURRENT_USER_SESSION_SECURE_V2: accountingBaseUrlSecure + "user/current-session-v2",
        GET_PREV_USER_SESSION_SECURE: accountingBaseUrlSecure + "user/prev-session",
        GET_CURRENT_SESSION_BY_USER: accountingBaseUrlSecure + "user/current-user-session",
        SESSION_AND_SUBSCRIPTION_PLAN_CHECKOUT: accountingBaseUrlSecure + "user/session-info",
        CREATE_HISTORY_MANUAL:accountingBaseUrlSecure + "accounting/create-manual-payment-history",
        CREATE_SETTINGS_DELAYS:accountingBaseUrlSecure + "accounting/change-setting-to-postpone",
        EDIT_COUPON:accountingBaseUrlSecure + "accounting/update-coupon",
        CREATE_SETTINGS_DISCOUNT:accountingBaseUrlSecure + "accounting/cange-expired-discount-at-by-session",
        CREATE_COUPON:accountingBaseUrlSecure + "accounting/create-coupon",
        GET_USER_SUBSCRIPTION_WITH_ID: accountingBaseUrlSecure + "user/subscriptions",
        MODIFY_MANUAL_ACCESS: accountingBaseUrlSecure + "user/access-update",
        REFUND_URL: accountingBaseUrlSecure + "accounting/subscription/refund",
        CANCEL_SUBSCRIPTION_URL: accountingBaseUrlSecure + "accounting/subscription/update/course-access",
        CUSTOM_PAYMENT_DATE: accountingBaseUrlSecure + "accounting/custom-payment-date",
        CUSTOM_SESSION_PAYMENT_DATE: accountingBaseUrlSecure + "accounting/session/custom-payment-date",
        UPDATE_CURRENT_SESSION: accountingBaseUrlSecure + "accounting/session/update-current-session",
        GET_CURRENT_SESSION: accountingBaseUrlSecure + "accounting/session/get-current-session",
        GET_SESSION_FILTER: accountingBaseUrlSecure + "accounting/session/filter",
        GET_COUPON_FILTER :accountingBaseUrlSecure + "accounting/filter-coupon",
        GET_SESSION_WITHOUT_PAGINATION: accountingBaseUrlSecure + "accounting/all-sessions",
        GET_ALL_COUPONS: accountingBaseUrlSecure + "accounting/get-all-coupon",   
        POSTPONED_PAYMENT_DATE:accountingBaseUrlSecure + "user/postpone-payment-date",
        GET_USER_INFORMATIONS: accountingBaseUrlSecure + "user/get-subscription-information",
        GET_USER_HISTORY_REPORT: accountingBaseUrlSecure + "user/postpone/history",
        MODIFY_USER_SUBSCRIPTION_PLAN: accountingBaseUrlSecure + "accounting/subscription/change-subscription-plan",
        GET_VALUE_TOGGLE: accountingBaseUrlSecure + "accounting/get-setting",
    },
    COURSE: {
        CREATE: accountingBaseUrl + "/v1/secure/accounting/courses",
        GET_ALL_CREATED_COURSE: accountingBaseUrl + "/v1/secure/accounting/courses",
        GET_ONE: accountingBaseUrl + "/v1/secure/accounting/courses",
        GET_ONE_UNSECURED: accountingBaseUrl + "/v1/unsecure/courses",
        GET_BY_FILTER: accountingBaseUrl + "/v1/secure/accounting/courses/status/filter",
        GET_PLAN_SUBSCRIPTION: accountingBaseUrl + "/v1/secure/accounting/courses/souscription/plan/add",
        DELETE: accountingBaseUrl + "/v1/secure/accounting/courses",
        DELETE_PLAN: accountingBaseUrl + "/v1/secure/accounting/courses/souscription/plan/remove",
        ALL_COURSES_WITHOUT_PAGINATION: accountingBaseUrlSecure + "accounting/no_paginate/courses",
        ALL_COURSES: accountingBaseUrl + "/v1/courses",
        ALL_UNSECURE_COURSES: accountingBaseUrl + "/v1/unsecure/courses",
        SETTINGS: accountingBaseUrl + "/v1/secure/accounting/settings",
        UPDATE: accountingBaseUrl + "/v1/secure/accounting/courses",
        SESSION: accountingBaseUrl + "/sessions",
        COURSE_AND_SUBSCRIPTION_PLAN_CHECKOUT: accountingBaseUrlSecure + "user/course-info",
        subscription_plans: accountingBaseUrl + "/subscription_plans",
        currency: accountingBaseUrl + "/currencies",
        channel: accountingBaseUrl + "/channels",
        CHECK_ACCES_COURSE_SECURE: accountingBaseUrlSecure + "user/check-acces",
        CHECK_ACCESS_TYPE_COURSE_SECURE: accountingBaseUrlSecure + "user/course/access-type",
        CHECK_ACCES_COURSE_SECURE_USER_AUTH:
            accountingBaseUrlSecure + "user/check-acces-user-auth",
        GET_USER_ONE_COURSE: accountingBaseUrlSecure + "user/get-a-course",
        GET_USER_ALL_COURSE: accountingBaseUrlSecure + "user/subscription-user",
        GET_USER_ALL_COURSE_DETAILS: accountingBaseUrlSecure + "user/course/access-type",
        CHECK_ACCESS_COURSE_SECURE_USER_WITH_USER_AND_SESSION:
            accountingBaseUrlSecure + "user/session-access",
        CHECK_ACCESS_COURSE_SECURE_USER_WITH_USER_AND_COURSE:
            accountingBaseUrlSecure + "user/course-access",
        CHECK_ACCESS_TYPE_SECURE: accountingBaseUrlSecure + "user/access-type",
        CHANGE_ACCESS_TYPE_TO_NORMAL_SECURE:
            accountingBaseUrlSecure + "user/change-access-type-to-normal",
        CHANGE_ACCESS_TYPE_TO_MANUAL_SECURE:
            accountingBaseUrlSecure + "user/change-access-type-to-manual",
        CHANGE_ACCESS_TYPE_TO_NORMAL_COURSE_SECURE:
            accountingBaseUrlSecure + "accounting/change_subscription_status_course",
        CHANGE_ACCESS_TYPE_TO_MANUAL_COURSE_SECURE:
            accountingBaseUrlSecure + "accounting/change_subscription_status_course",
        COURSE_MODIFY_MANUAL_ACCESS: accountingBaseUrlSecure + "user/change-access-type-to-manual",
        REFUND_URL: accountingBaseUrlSecure + "accounting/subscription/refund",
        CANCEL_SUBSCRIPTION_URL: accountingBaseUrlSecure + "accounting/subscription/update/course-access",
        REFUND_TRANSACTION: accountingBaseUrlSecure + "accounting/refund_payment_history",
        showCourseStats: accountingBaseUrlSecure + "accounting/statistics/course",


    },
    RESOURCE: {
        CREATE: accountingBaseUrl + "/resources",
        GET_ALL: accountingBaseUrl + "/resources",
        GET_ONE: accountingBaseUrl + "/resources",
        DELETE: accountingBaseUrl + "/resources",
        UPDATE: accountingBaseUrl + "/resources",
    },
    PaymentHistory: {
        GET_HISTORY_PAYMENT_SECURE: accountingBaseUrlSecure + "user/history-payment",
        GET_DATE_PAYMENT_SECURE: accountingBaseUrlSecure + "user/date-payment",
        GET_DATE_PAYMENT_SECURE_ADMIN: accountingBaseUrlSecure + "accounting/date-payment-all-user",
        GET_PAYMENT_HISTORY_FAIL_SECURE_ADMIN: accountingBaseUrlSecure + "accounting/payment-hystory-fail",
        GET_INSTALLMENT_SECURE: accountingBaseUrlSecure + "user/installment-payment",
        GET_DATE_PAYMENT_SECURE_ADMIN_COURSE: accountingBaseUrlSecure + "accounting/date-payment-all-user-by-course",
        GET_PAYMENT_HISTORY_FAIL_SECURE_ADMIN_COURSE: accountingBaseUrlSecure + "accounting/payment-hystory-fail-course",
    },
    SubscriptionPlan: {
        GET_MOST_USED_PLAN: accountingBaseUrlSecure + "accounting/most-used-plan",
    },

    DashboardAdmin: {
        GET_ALL: accountingBaseUrl + "/v1/secure/user/overview",
    },

    Transaction: {
        logTransaction: accountingBaseUrlSecure + "user/all-transactions",
        getSessionFilter: accountingBaseUrlSecure + "accounting/all-sessions"
    },
    
    searchTransaction: {
        GET_ALL: accountingBaseUrlSecure + "support/search_payment_history"
    },
    searchUserTransaction: {
        GET_USER_TRANSACTION: accountingBaseUrlSecure + "support/search_user"
    },
    LastSubscription: {
        GET_ALL: accountingBaseUrlSecure + "user/last-transaction",
    },
    LastSubscriptionCourse: {
        GET_ALL: accountingBaseUrlSecure + "user/last-transaction-course",
    },
    Subscription: {
        ADD_SUBSCRIPTION: accountingBaseUrlSecure + "accounting/discount",
        MANUAL_SYNCHRONIZATION : accountingBaseUrlSecure +"accounting/synchronisation-service",
        ACCOUNT_SYNCHRONIZATION : accountingBaseUrlSecure+"accounting/import-excel",

    }
}
export default backendUrls;